<template>
  <div>
    <!-- 头部 -->
    <mHeader :dlogBr="true" val="yes"></mHeader>
    <!-- 进度条 -->
    <Progress :val="2" type="mb"></Progress>

    <div class="neiron">
      <div class="form-box">
        <div class="formBox-tit">*Required Field</div>
        <div class="formBox-form">
          <el-form
            :rules="passengerInfoRules"
            ref="passengerInfoRef"
            label-position="top"
            label-width="80px"
            :model="pasInfoForm"
          >
            <el-form-item prop="firstName" :label="'First Name'">
              <el-input
                v-model="pasInfoForm.firstName"
                maxlength="30"
              ></el-input>
            </el-form-item>
            <el-form-item prop="lastName" :label="'Last Name'">
              <el-input
                v-model="pasInfoForm.lastName"
                maxlength="30"
              ></el-input>
            </el-form-item>

            <el-form-item prop="email" :label="'Email Address'">
              <el-input v-model="pasInfoForm.email"></el-input>
            </el-form-item>
            <div class="el-form-affirmEmail">
              <el-form-item prop="affirmEmail" :label="'Confirm Email Address'">
                <el-input v-model="pasInfoForm.affirmEmail"></el-input>
              </el-form-item>
            </div>

            <!-- 隐私政策 -->
            <div class="el-form-chebox">
              <el-form-item prop="chebox">
                <el-checkbox-group v-model="pasInfoForm.chebox">
                  <el-checkbox @change="yueduTyFn" name="chebox"
                    >l have read and agreed to the
                    <a @click="serviceDialogBr = true" href="javascript:;"
                      >Terms of Service</a
                    >
                    &
                    <a @click="policyDialogBr = true" href="javascript:;"
                      >Privacy Policy</a
                    >
                    &
                    <a href="javascript:;" @click="noticeDialogBr = true"
                      >Notice</a
                    >.
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>

              <el-form-item prop="chebox2">
                <el-checkbox-group v-model="pasInfoForm.chebox2">
                  <el-checkbox @change="yueduTyFn2" name="chebox"
                    >Please note that if the above information is incomplete or
                    incorrect, we reserve the right to cancel the
                    order.</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="btm-btn">
      <!--  -->
      <div v-if="param" class="price">
        <p>Total</p>
        <span>￥{{ setSumPrice(param.totalPrice, 1) || "-" }}</span>
      </div>
      <!-- 抽屉 按钮 -->
      <div @click="drawerBuer = true" class="drawerBtn">
        Booking Details <i class="el-icon-arrow-up"></i>
      </div>
      <!-- 按钮 -->
      <el-button :loading="btnLoadingBr" @click="continueBtn">Continue</el-button>
    </div>

    <!-- 抽屉 -->
    <div class="drawer-box">
      <el-drawer
        :visible.sync="drawerBuer"
        direction="btt"
        :before-close="handleClose"
      >
        <div class="right">
          <div v-if="param" class="right-tit">
            <p>Moominvalley Park 1-Day Pass</p>
            <span
              >{{ translateMonth(param.date.split("-")[1]) }}
              {{ param.date.split("-")[2] }},
              {{ param.date.split("-")[0] }}</span
            >
          </div>
          <div v-if="param && param.adult" class="right-adult">
            <div class="item">
              <p>Quantity</p>
              <span>{{ param.adult ? param.adult + "x" : "-" }} Adult</span>
            </div>
            <div class="item">
              <p>Subtotal</p>
              <span
                >￥{{ setSumPrice(param.adultPrice, param.adult) || "-" }}</span
              >
            </div>
          </div>
          <div v-if="param && param.child" class="right-child">
            <div class="item">
              <p>Quantity</p>
              <span>{{ param.child ? param.child + "x" : "-" }} Child</span>
            </div>
            <div class="item">
              <p>Subtotal</p>
              <span
                >￥{{ setSumPrice(param.childPrice, param.child) || "-" }}</span
              >
            </div>
          </div>
          <div v-if="param && param.student" class="right-child">
            <div class="item">
              <p>Quantity</p>
              <span>{{ param.student ? param.student + "x" : "-" }} Secondary Student</span>
            </div>
            <div class="item">
              <p>Subtotal</p>
              <span
                >￥{{ setSumPrice(param.studentPrice, param.student) || "-" }}</span
              >
            </div>
          </div>
          <!-- 总价 -->
          <div class="right-total">
            <div v-if="param" class="right-total-jg">
              <p>Total</p>
              <span>￥{{ setSumPrice(param.totalPrice, 1) || "-" }}</span>
            </div>

            <div @click="drawerBuer = false" class="drawerBtn">
              Booking Details <i class="el-icon-arrow-down"></i>
            </div>
          </div>

          <!-- 按钮 -->
          <el-button :loading="btnLoadingBr" @click="continueBtn">Continue</el-button>
        </div>
      </el-drawer>
    </div>
    <!-- 下一步 弹窗 -->
    <div class="nextDialog">
      <el-dialog
        :visible.sync="nextDialogBr"
        width="93vw"
        :before-close="handleClose"
      >
        <div class="title">Moominvalley Park 1-Day Pass</div>
        <div class="mainDlg">
          <p>• Refundable three days before the day of visit.</p>
          <p>
            • Re-entering the park on a valid day is allowed. Please show the
            verified ticket to the staff when re-entering.
          </p>
          <p>
            • When guests enter the Park, click the E-ticket URL and the
            E-ticket will be displayed on the page. Confirm your booking
            information for the E-ticket, then press the "Confirm" button, and
            show the verified ticket to the staff.
          </p>
          <p>
            • When purchasing more than one ticket, each guest has to show the
            verified ticket to the staff.
          </p>
          <p>
            • Reselling tickets or providing any ticket for the purpose of
            reselling to a third party is strictly prohibited whether it is for
            profit or not.
          </p>
          <p>
            • Tickets obtained by violating any of these prohibitions will be
            void. Refunds of those tickets are not allowed.
          </p>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            style="width: 100%"
            @click="nextDialogBr = false"
            type="primary"
            >OK</el-button
          >
        </span>
      </el-dialog>

      <!-- 链接弹窗 -->
      <div class="nextDialog">
        <el-dialog
          :visible.sync="serviceDialogBr"
          width="93vw"
          :before-close="handleClose"
          :title="serviceHtml.title"
        >
          <div class="neir">
            <div v-html="serviceHtml.content" class="neir-box"></div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button
              style="width: 100%"
              @click="serviceDialogBr = false"
              type="primary"
              >Close</el-button
            >
          </span>
        </el-dialog>
      </div>

      <div class="nextDialog">
        <el-dialog
          :visible.sync="policyDialogBr"
          width="93vw"
          :before-close="handleClose"
          :title="privacyHtml.title"
        >
          <div class="neir">
            <div v-html="privacyHtml.content" class="neir-box"></div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button
              style="width: 100%"
              @click="policyDialogBr = false"
              type="primary"
              >Close</el-button
            >
          </span>
        </el-dialog>
      </div>

      <div class="nextDialog">
        <el-dialog
          :visible.sync="noticeDialogBr"
          width="93vw"
          :before-close="handleClose"
          :title="precautionsHtml.title"
        >
          <div class="neir">
            <div v-html="precautionsHtml.content" class="neir-box"></div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button
              style="width: 100%"
              @click="noticeDialogBr = false"
              type="primary"
              >Close</el-button
            >
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import mHeader from "@/components/m_header.vue";
import Progress from "@/components/progress.vue";
import {
  post_orderInfo,
  get_service,
  get_privacy,
  get_precautions,
} from "@/api/common.js";
export default {
  data() {
    return {
      btnLoadingBr: false, // 按钮请求状态
      serviceHtml: "",
      privacyHtml: "",
      precautionsHtml: "",
      noticeDialogBr: false,
      policyDialogBr: false,
      serviceDialogBr: false,
      param: JSON.parse(localStorage.getItem("orderParam")) || {}, // 订单参数
      drawerBuer: false, // 抽屉弹窗
      btnBr: false, // 按钮样式
      btnBr2: false, // 按钮样式
      pasInfoForm: JSON.parse(localStorage.getItem("pasInfoForm")) || {
        firstName: "",
        lastName: "",
        email: "",
        affirmEmail: "",
        chebox: [],
        chebox2: [],
      },
      passengerInfoRules: {
        firstName: [
          {
            required: true,
            message: "Please enter your first name",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please enter your last name",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please enter a valid email address",
            trigger: "blur",
          },
          {
            pattern:
              /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            message: "Please enter a valid email address",
            trigger: "blur",
          },
        ],
        affirmEmail: [
          {
            required: true,
            message: "Please enter a valid email address",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("Please enter a valid email address"));
              } else if (value !== this.pasInfoForm.email) {
                callback(new Error("Please enter a valid email address"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        chebox: [
          {
            type: "array",
            required: true,
            message: " ",
            trigger: "change",
          },
        ],
        chebox2: [
          {
            type: "array",
            required: true,
            message: " ",
            trigger: "change",
          },
        ],
      },
      nextDialogBr: false, // 下一步弹窗状态
    };
  },
  methods: {
    // 获取隐私条款
    async getServiceFn() {
      let res = await get_service();
      this.serviceHtml = res.data.data;
    },

    async getPrivacy() {
      let res = await get_privacy();
      this.privacyHtml = res.data.data;
    },

    async getPrecautions() {
      let res = await get_precautions();
      this.precautionsHtml = res.data.data;
    },

    // 下一步
    continueFn() {
      // console.log(this.param);
      this.nextDialogBr = true;
    },
    handleClose(done) {
      done();
    },
    // 下一步按钮
    async continueBtn() {
      this.btnLoadingBr = true;
      try {
        await this.$refs.passengerInfoRef.validate();
        this.param = {
          ...this.param,
          contact: `${this.pasInfoForm.firstName} ${this.pasInfoForm.lastName}`,
          gmail: this.pasInfoForm.email,
        };

        await post_orderInfo({
          date: this.param.date,
          adult: this.param.adult,
          child: this.param.child,
          student: this.param.student,
          first_name: this.pasInfoForm.firstName,
          last_name: this.pasInfoForm.lastName,
          email: this.pasInfoForm.email,
        })
          .then((res) => {
            this.btnLoadingBr = false;
            console.log(res);
            // 用户资料存本地
            localStorage.setItem("orderParam", JSON.stringify(this.param));
            localStorage.setItem(
              "pasInfoForm",
              JSON.stringify({ ...this.pasInfoForm, chebox: [], chebox2: [] })
            );

            this.$router.push({
              path: "/m_payment",
              // query: {
              //   id: res.data.data.id,
              // },
            });
            sessionStorage.setItem("moominOrderId", res.data.data.id);
          })
          .catch((err) => {
            this.btnLoadingBr = false;
            console.log(err);
            this.$message.warning("Fail to continue");
            // this.$router.push({ path: "/" });
          });
      } catch (error) {
        this.btnLoadingBr = false;
      }
    },
    // a链接点击跳转
    aLianjieFn(val) {
      this.$router.push(val);
    },
    // 阅读并同意
    yueduTyFn(val) {
      // console.log(val);
      this.btnBr = val;
    },
    // 阅读并同意
    yueduTyFn2(val) {
      // console.log(val);
      this.btnBr2 = val;
    },
  },
  mounted() {
    if (this._isMobile()) {
      // 跳转至手机端路由
      if (this.$route.path != "/m_info") {
        this.$router.replace("/m_info");
      }
    } else {
      // 跳转至 pc 端路由
      if (this.$route.path != "/info") {
        this.$router.replace("/info");
      }
    }
  },
  watch: {
    $route() {},
  },
  created() {
    this.getServiceFn();
    this.getPrivacy();
    this.getPrecautions();

    // console.log("info_create触发");
    window.scrollTo(0, 0);
    console.log(this.param);

    this.param = JSON.parse(localStorage.getItem("orderParam"));
    if (JSON.parse(localStorage.getItem("pasInfoForm"))) {
      // console.log("触发route");
      this.pasInfoForm = JSON.parse(localStorage.getItem("pasInfoForm"));
    }

    if (!JSON.parse(localStorage.getItem("orderParam"))) {
      this.$router.push({
        path: "/m_home",
      });
    }
  },
  components: {
    mHeader,
    Progress,
  },
};
</script>

<style lang="less" scoped>
// 下一步弹窗
.nextDialog {
  /deep/.el-button + .el-button {
    margin-left: 20px;
  }
  /deep/.el-dialog__header {
    padding: 0;
  }
  /deep/.el-dialog__body {
    padding: 0;
    font-size: 14px;
    margin-bottom: 20px;
    color: #12334c;
    .title {
      word-break: break-word;
      font-size: 16px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .mainDlg {
      p {
        word-break: break-word;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
  }
  /deep/.el-dialog__footer {
    padding: 0;
  }
  /deep/.el-button {
    width: 159px;
    height: 42px;
    font-size: 16px;
  }
  /deep/.dialog-footer {
    // width: 348px;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
  }

  /deep/.el-dialog {
    // padding: 8vw 5.3333vw;
    border-radius: 12px;
    margin-top: 12vh !important;
    height: 70vh;
    display: flex;
    flex-direction: column;
    .el-dialog__header {
      padding: 5vw 5.3333vw 4vw;

      // 隐藏title
      display: none;
    }
    .el-dialog__body {
      overflow: auto;
      flex: 1;
      padding: 0 5.3333vw;
    }
    .el-dialog__footer {
      padding: 0 5.3333vw 5.3333vw;
    }
  }
  /deep/.el-icon-close:before {
    content: "";
  }
  /deep/.el-button--default {
    border-radius: 7px;
    border: 1px solid #12334c;
    color: #12334c;
  }
  /deep/.el-button--primary {
    border-radius: 7px;
    border: 1px solid #12334c;
    color: #fff;
    background-color: #12334c;
  }

  .neir-box {
    word-break: break-word;
    padding-top: 30px;
    .neir-main {
      margin-bottom: 28px;
      .item-da-tit {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 12px;
      }
      .item-xiao-tit {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .item-zhon-tit {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 16px;
      }
      .ppp {
        margin-bottom: 16px;
        p {
          margin-bottom: 16px;
        }
      }
      .item-main {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
        p {
          margin-bottom: 16px;
        }
        .dl {
          padding-left: 16px;
        }
      }
    }
  }
}
.neiron {
  padding: 16px 16px 0;

  .form-box {
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;

    .formBox-tit {
      margin-bottom: 2.6667vw;
      color: #889098;
      font-weight: 400;
      font-size: 3.7333vw;
    }
  }
  /deep/.el-form-item {
    margin-bottom: 5.3333vw;
  }

  /deep/.el-form-chebox {
    .el-checkbox__label {
      font-size: 3.2vw;
      line-height: 5.0667vw;
      a {
        color: #0f73a0 !important;
      }
    }
    .el-checkbox__inner {
      width: 3.7333vw;
      height: 3.7333vw;
      border-radius: 0.5333vw;
      &::after {
        height: 1.8667vw;
        left: 1.0667vw;
        top: 0.2667vw;
        width: 0.8vw;
      }
    }
    .el-form-item {
      margin-bottom: 17px;
      &:last-child {
        margin-bottom: 0;
      }

      &.is-error {
        .el-checkbox {
          color: #f56c6c;
          a {
            color: #f56c6c;
          }
        }
        .el-checkbox__inner {
          border: 1px solid #f56c6c;
        }

        .el-input__inner:focus {
          border-color: #f56c6c;
        }
      }
    }
  }
  /deep/.el-form-affirmEmail {
    .el-form-item {
      margin-bottom: 6.6667vw;
    }
  }

  /deep/.el-input__inner {
    height: 12vw;
    border-radius: 7px;
    font-size: 4.2667vw;
    &:focus {
      border-color: #12334c;
    }
  }
  /deep/.is-error {
    .el-input__inner:focus {
      border-color: #f56c6c;
    }
  }

  /deep/.el-form-item__label {
    line-height: unset;
    font-size: 4.2667vw;
    color: #12334c;
    padding-bottom: 1.3333vw;
  }

  /deep/.el-checkbox {
    white-space: pre-wrap;
    display: flex;
    a {
      color: #0f73a0;
    }
  }
  /deep/.el-checkbox__input {
    padding-top: 0.5333vw;
  }
  /deep/.el-form-item__error {
    color: #f56c6c;
    font-size: 3.2vw;
    line-height: 1;
    padding-top: 8px;
    position: absolute;
    top: 100%;
    left: 0;
  }

  /deep/.el-form-item.is-required:not(.is-no-asterisk)
    > .el-form-item__label:before {
    content: "";
    color: #12334c;
    margin-right: 4px;
  }
  /deep/.el-form-item.is-required:not(.is-no-asterisk)
    > .el-form-item__label::after {
    content: " *";
    color: #12334c;
    margin-right: 4px;
  }

  /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
    color: #12334c;
  }

  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #12334c;
    border-color: #12334c;
  }
}

//
.btm-btn {
  background-color: #fff;
  margin-top: 16px;
  padding: 18px 16px 16px;
  .price {
    display: flex;
    justify-content: space-between;
    font-size: 5.3333vw;
    color: #12334c;
    font-weight: 700;
  }

  .el-button {
    padding: 3.2vw 0;
    width: 100%;
    background-color: #12334c;
    color: #fff;
    border-radius: 7px;
    font-size: 4.2667vw;
  }
}

.drawer-box {
  /deep/.el-drawer {
    height: unset !important;
  }
  /deep/.el-drawer__header {
    padding: 16px 16px 0;
    margin-bottom: 0;

    .el-drawer__close-btn {
      width: 6.4vw;
      border-radius: 50%;
      background-color: #f0f0f0;
      height: 6.4vw;
      font-size: 3.7333vw;
    }
  }

  /deep/.el-drawer__body {
    padding: 16px;

    .right {
      background-color: #fff;

      .right-tit {
        border-bottom: 1px solid #b2bac13d;
        padding-bottom: 16px;
        font-size: 4.8vw;
        color: #12334c;
        font-weight: bold;
        p {
          margin-bottom: 5px;
        }
        span {
          font-size: 4.2667vw;
        }
      }
      .right-adult,
      .right-child {
        height: 21.8667vw;
        padding: 4.2667vw 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 3.7333vw;
        border-bottom: 1px solid #b2bac13d;
        color: #12334c;
        font-size: 16px;
        .item {
          display: flex;
          justify-content: space-between;
          p {
            font-weight: 400;
          }
          span {
            font-weight: bold;
          }
        }
      }
      .right-total {
        padding-top: 4.2667vw;
        font-weight: bold;
        .right-total-jg {
          display: flex;
          justify-content: space-between;
          font-size: 5.3333vw;
          color: #12334c;
        }
      }

      .el-button {
        width: 100%;
        background-color: #12334c;
        color: #fff;
        padding: 3.2vw 0;
        border-radius: 7px;
        font-size: 4.2667vw;
      }
    }
  }
}

.drawerBtn {
  // width: 27.4667vw;
  display: inline-block;
  height: 5.8667vw;
  border-radius: 1.3333vw;
  background: #f3f4f5;
  font-size: 3.2vw;
  color: #12334c;
  line-height: 5.8667vw;
  text-align: left;
  padding-left: 0.5333vw;
  padding-right: 0.5333vw;
  font-weight: 400;
  margin: 1.0667vw 0 4.2667vw;
}
</style>